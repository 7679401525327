'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar, A11y, Mousewheel} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

const SliderMulti = {
    els: null,
    init: function () {
        SliderMulti.els = document.querySelectorAll('.f-slider.multi--');
        if (SliderMulti.els && SliderMulti.els.length > 0) {
            SliderMulti.els.forEach(el => {
                SliderMulti.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;

        if (slider.dataset.navigation === "fleches") {
            swiper = new Swiper(slider, {
                modules: [Navigation, A11y],
                a11y: true,
                spaceBetween: 40,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight:false,
                navigation: {
                    prevEl: ".f-slider__navigation-prev",
                    nextEl: ".f-slider__navigation-next",
                }
            });
        }
        if (slider.dataset.navigation === "scrollbar") {
            swiper = new Swiper(slider, {
                modules: [Scrollbar, A11y],
                a11y: true,
                spaceBetween: 40,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight:false,
                scrollbar: {
                    el: ".f-slider__scrollbar",
                    hide: false,
                    draggable: true
                }
            });
        }
        if (slider.dataset.navigation === "none") {
            swiper = new Swiper(slider, {
                slidesPerView: "auto",
                modules: [Mousewheel],
                loop: true,
                speed: 900,
                autoHeight: false,
                freeMode: true,
                mousewheel: {
                    releaseOnEdges: true,
                    forceToAxis:true
                },
                direction: "horizontal",
                breakpoints: {
                    768: {
                        centeredSlides: false,
                    },
                }
            });
        }

    },
};

export default SliderMulti;
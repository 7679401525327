import Header from "./components/Header";
import SliderSingle from "./blocks/diaporama_single-slider_image_animee_texte_fixe-slider_image_texte_animes";
import SliderMulti from "./blocks/diaporama_multi";
import SliderTrombi from "./blocks/diaporama_trombi";
import SliderMarquee from "./blocks/diaporama_marquee";
import SliderRef from "./blocks/diaporama_ref";
import Video from "./blocks/video";
import Accordion from "./blocks/accordeon";
import Form from "./components/Form";
import Select from "./components/Select";
import Animations from "./utils/animations";


const main = {
    init: function() {
        Header.init();
        SliderSingle.init();
        SliderMulti.init();
        SliderTrombi.init();
        SliderMarquee.init();
        SliderRef.init();
        Video.init();
        Accordion.init();
        Select.init();
        Form.init();
        Animations.init();
    }
};

export default main
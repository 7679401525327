'use strict';

// core version + modules:
import Swiper, {A11y, Mousewheel} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';

const SliderRef = {
    els: null,
    ctaPopins: null,
    popin: null,
    init: function () {
        SliderRef.els = document.querySelectorAll('.f-slider.ref--');
        if (SliderRef.els && SliderRef.els.length > 0) {
            SliderRef.els.forEach(el => {
                if(window.innerWidth <= 768)
                    SliderRef.create(el);
            });
        }
        SliderRef.ctaPopins = document.querySelectorAll('.f-slider .c-button.popin--');

        SliderRef.popin = document.querySelector('.c-popin.popinref--');
        console.log( document.querySelectorAll('.f-slider .c-button.popin--'))
        SliderRef.event();
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;

        swiper = new Swiper(slider, {
            modules: [A11y, Mousewheel],
            a11y: true,
            spaceBetween: 20,
            slidesPerView: "auto",
            loop: false,
            speed: 600,
            autoHeight:false,
            centeredSlides: true,
            loop:true,
            // mousewheel: {
            //     releaseOnEdges: false,
            //     forceToAxis:true
            // },
            direction: "horizontal",
            breakpoints: {
                768: {
                    spaceBetween: 40,
                    loop:false,
                    centeredSlides: false,
                },
            }
        });
    },
    event: function() {
        if(SliderRef.popin) {
            if(SliderRef.ctaPopins.length > 0) {
                SliderRef.ctaPopins.forEach(cta => {
                    cta.addEventListener('click', function (event) {
                        event.preventDefault();
                        console.log(cta)
                        SliderRef.showPopin(cta.closest('.f-references__card').querySelector('.f-references__popin').innerHTML);
                    });
                });
            }

            if(SliderRef.popin.querySelector('.c-popin__overlay')) {
                SliderRef.popin.querySelector('.c-popin__overlay').addEventListener('click', function(event) {
                    event.preventDefault();
                    SliderRef.hidePopin();
                })
                
            }
            if(SliderRef.popin.querySelector('.c-popin__close')) {
                SliderRef.popin.querySelector('.c-popin__close').addEventListener('click', function(event) {
                    event.preventDefault();
                    SliderRef.hidePopin();
                })
                
            }
        }
    },
    showPopin: function(content) {
        SliderRef.popin.classList.add('show--')
        SliderRef.popin.querySelector('.c-popin__content').innerHTML = content;
    },
    hidePopin: function() {
        SliderRef.popin.classList.remove('show--');
        SliderRef.popin.querySelector('.c-popin__content').innerHTML = '';
    }
};

export default SliderRef;
'use strict';

// core version + modules:
import Swiper, {A11y, Mousewheel} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';

const SliderTrombi = {
    els: null,
    init: function () {
        SliderTrombi.els = document.querySelectorAll('.f-slider.trombi--');
        if (SliderTrombi.els && SliderTrombi.els.length > 0) {
            SliderTrombi.els.forEach(el => {
                if(window.innerWidth <= 768)
                    SliderTrombi.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        swiper = new Swiper(slider, {
            modules: [A11y, Mousewheel],
            a11y: true,
            slidesPerView: "auto",
            loop: false,
            speed: 900,
            autoHeight:false
        });

    },
};

export default SliderTrombi;
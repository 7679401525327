'use strict';

const Header =  {
    el:null,
    languages:null,
    toggle:null,
    close:null,
    init: function(){
        Header.el = document.querySelector('.header');
        if(Header.el){
            Header.toggle=Header.el.querySelector('.header__toggle.menu');
            Header.close=Header.el.querySelector('.header__toggle.close');
            Header.languages = Header.el.querySelector('.header__languages')
            if(Header.toggle){
                Header.toggle.addEventListener('click', () => {
                   Header.el.classList.toggle('navOpened');
                });
            }
            if(Header.close){
                Header.close.addEventListener('click', () => {
                   Header.el.classList.toggle('navOpened');
                });
            }
            if(Header.languages) {
                Header.language();
            }
        }
    },
    language: function() {
        var languageList = Header.languages.querySelector('.header__languages-list');
    
        Header.languages.querySelector('.header__languages-selected').addEventListener('click', function () {
            Header.languages.classList.toggle('show--')
        });
    
        languageList.addEventListener('click', function (event) {
            if (event.target.tagName === 'SPAN' || event.target.tagName === 'A') {
                Header.languages.classList.remove('show--');
                Header.languages.querySelector('.header__languages-selected').textContent = event.target.textContent;
            }
        });
    
        // Cacher la liste déroulante si on clique en dehors
        window.addEventListener('click', function (event) {
            if (!Header.languages.contains(event.target)) {
                Header.languages.classList.remove('show--')
            }
        });
    }
};

export default Header;

const Animations = {
    elts: null,
  
    init: function() {
        Animations.elts = document.querySelectorAll('.animate');
        if (Animations.elts && Animations.elts.length > 0) {
            console.log(Animations.elts)
            Animations.handleScroll();
            Animations.event();
        }
    },
  
    event: function() {
        window.addEventListener('scroll', Animations.handleScroll);
    },
  
    handleScroll: function() {
        Animations.elts.forEach(function(element) {
            var elementTop = element.getBoundingClientRect().top;
            var isScrolledToElement = elementTop < window.innerHeight;
            Animations.updateVisibility(element, isScrolledToElement);
        });
    },
  
    updateVisibility: function(element, isVisible) {
        if (isVisible) {
            element.classList.add('visible--');
        } else {
            element.classList.remove('visible--');
        }
    },
  };
  
  export default Animations;

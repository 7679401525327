'use strict';

// core version + modules:
import Swiper, {A11y, Autoplay} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';

const SliderMarquee = {
    els: null,
    init: function () {
        SliderMarquee.els = document.querySelectorAll('.f-slider.marquee--');
        if (SliderMarquee.els && SliderMarquee.els.length > 0) {
            SliderMarquee.els.forEach(el => {
                SliderMarquee.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;

        swiper = new Swiper(slider, {
            modules: [A11y, Autoplay],
            a11y: true,
            spaceBetween: 40,
            slidesPerView: "auto",
            loop: true,
            speed: 600,
            autoHeight:false,
            speed: 4000,
            autoplay: {
                delay: 1
            },
            allowTouchMove: false,
            centeredSlides: true
        });

    },
};

export default SliderMarquee;